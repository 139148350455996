import {
  createAsyncThunk,
  createSlice,
  current,
  useSelector,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { setUserLanguage } from "./userAction";

// const getUsers = createAsyncThunk(
//     "user/getUsers",
//     async () => {
//       const data = await axios
//         .get("/api/rest/data/read/User")
//         .then((res) => {
//           return res.data.value;
//             // const filterFields = ["id", "email", "username", "language", "loginSuccess"]
//             // return res.data.value.map(elem => {
//             //   const oggettoFiltrato = Object.fromEntries(
//             //     Object.entries(elem).filter(([field]) => filterFields.includes(field))
//             //   );
//             //   return oggettoFiltrato
//             // });
//         })
//         .catch((error) => {
//           console.log("Error: ", error);
//           throw error;
//         });
//       // console.log('data: ', data);
//       return data;
//     }
// )

const getTenantsUsers = createAsyncThunk(
  "user/getTenantsUsers",
  async (tenantIds) => {
    const data = await axios
      .post("/api/py/readTenantsUsers", {
        tenantIds: tenantIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return data;
  }
);

const getUserRoles = createAsyncThunk("user/getUserRoles", async () => {
  const data = await axios
    .get("/api/rest/data/meta/UserRole")
    .then((res) => {
      if (res.data.value) {
        // console.log('inside getUser asyncthunk');
      } else return null;
    })
    .catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
  // console.log('data: ', data);
  return data;
});

const getMyUser = createAsyncThunk("user/getMyUser", async (payload) => {
  const { sqlId } = payload;
  const params = { sqlId: sqlId, appId: '1' };
  const data = await axios
    .get("/api/py/readUser", { params })
    .then((res) => {
      // console.log('res.data: ', res.data);
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
  return data;
});

// const updateUser = createAsyncThunk(
//   "user/updateUser",
//   async ({username, language, profileImage}) => {
//     const data = await axios.put("/api/user/update", {
//       username: username,
//       language: language,
//       profileImage: profileImage,// base64
//     }).then((res) => {
//       return res.data;
//     }).catch((error) => {
//       throw error;
//     });
//     return data;
//   }
// );

const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({
    sqlId,
    roleId,
    language,
    proPic,
    password,
    currentPassword,
    fcmToken,
  }) => {

    const data = await axios
      .post("/api/py/updateUser", {
        sqlId: sqlId,
        roleId: roleId,
        language: language,
        proPic: proPic, // base64
        password: password,
        oldPassword: currentPassword,
        notifTokens: {'67bdaa173d41b6c4282fce75': fcmToken},
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const inviteUser = createAsyncThunk(
  "user/inviteUser",
  async ({ email, username, language, role }) => {
    const data = await axios
      .post("/api/user/invite", {
        email: email,
        username: username,
        app: "evocs",
        language: language, // it || en
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const createInvitedUser = createAsyncThunk(
  "user/createInvitedUser",
  async ({ email, tenantId, roleId }) => {
    const data = await axios
      .post("/api/py/createInvitedUser", {
        email: email,
        tenantId: tenantId,
        roleId: roleId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const activateUser = createAsyncThunk(
  "user/activateUser",
  async ({ email, username, password, secureCode, language, userImage }) => {
    // Salva temporaneamente il valore di Authorization
    const savedAuthorizationHeader =
      axios.defaults.headers.common.Authorization;

    // Rimuovi l'header Authorization per questa specifica richiesta
    delete axios.defaults.headers.common.Authorization;

    try {
      const data = await axios
        .post("/api/user/activate", {
          email: email,
          username: username,
          password: password,
          secureCode: secureCode,
          language: language, // it || en
          profileImage: userImage, // base64
        })
        .then((res) => {
          return res.data;
        });
      return data;
    } catch (error) {
      throw error;
    } finally {
      // Ripristina il valore di Authorization dopo la richiesta
      axios.defaults.headers.common.Authorization = savedAuthorizationHeader;
    }
  }
);

// const activateUser = createAsyncThunk(
//   "user/activateUser",
//   async ({username, password, secureCode, language, userImage}) => {
//     const data = await axios.post("/api/user/activate", {
//       username: username,
//       password: password,
//       secureCode: secureCode,
//       language: language, // it || en
//       profileImage: userImage, // base64
//     }).then((res) => {
//       return res.data;
//     }).catch((error) => {
//       console.log("Error: ", error);
//       throw error;
//     });
//     return data;
//   }
// );

const passwordForget = createAsyncThunk(
  "user/passwordForget",
  async ({ username, email }) => {
    const data = await axios
      .post("/api/user/passwordForget", {
        username: username,
        email: email,
        app: "evocs",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

// ###################### NOTIFICATION ###################

const readAlerts = createAsyncThunk(
  "user/readAlerts",
  async ({ alertIds, lang }) => {
    const data = await axios
      .post("/api/py/readAlerts", {
        alertIds: alertIds,
        lang: lang,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const readAlertsByUser = createAsyncThunk(
  "user/readAlertsByUser",
  async ({ userId, startDate, endDate }) => {
    const data = await axios
      .post("/api/py/readAlertsByUser", {
        userId: userId,
        // alertIds: alertIds,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const deleteAlertsByUser = createAsyncThunk(
  "user/deleteAlertsByUser",
  async ({ userId, alertIds }, { dispatch, getState }) => {
    const data = await axios
      .post("/api/py/deleteAlertsByUser", {
        userId: userId,
        alertIds: alertIds,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return { oldAlerts: getState().user.alerts, removedAlerts: alertIds };
  }
);

const checkRecaptchaV3 = createAsyncThunk(
  "user/checkRecaptchaV3",
  async ({ token }) => {
    try {
      const response = await axios.post(
        `/api/py/stream/checkRecaptcha?responseToken=${token}`,
        {
          responseToken: token,
        }
      );

      if (!response.data.success) {
        throw new Error("Generic error on recaptcha V3");
      }

      return true;
    } catch (error) {
      console.log("Error: ", error);
      throw error;
    }
  }
);

const updateCookies = createAsyncThunk(
  "user/updateCookies",
  async ({ userId, tenantId, dictUpdate }) => {
    const data = await axios
      .post("/api/py/updateCookies", {
        dictUpdate: {
          userId: userId,
          tenantId: tenantId,
          ...dictUpdate,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        console.log("Error: ", error);
        throw error;
      });

    return data;
  }
);

const readInvitedUsers = createAsyncThunk(
  "user/readInvitedUsers",
  async ({ tenantId }) => {
    const data = await axios
      .post("/api/py/readInvitedUsers", {
        tenantId: tenantId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const deleteInvitedUsers = createAsyncThunk(
  "user/deleteInvitedUsers",
  async ({ userIds, tenantId }) => {
    const data = await axios
      .post("/api/py/deleteInvitedUsers", {
        userIds: userIds,
        tenantId: tenantId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
    return data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    fcmToken : null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedTenant: (state, action) => {
      state.selectedTenant = action.payload;
    },
    setRole: (state, action) => {
      state.roleId = action.payload;
    },
    setLastSelectedTenant: (state, action) => {
      state.lastSelectedTenant = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },    
    setFcmtoken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyUser.fulfilled, (state, action) => {
      if (!action.payload) return;
      return { ...state, ...action.payload };
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (!action.payload) return;
      return { ...state, ...action.payload };
      // if(action.payload?.id === state.id) console.log("update my user!")
    });
    builder.addCase(readAlertsByUser.fulfilled, (state, action) => {
      if (!action.payload) return;
      if (state.alerts?.length !== action.payload.length)
        state.alerts = action.payload;
      else if (
        state.alerts?.every(
          (element, index) => element === action.payload[index]
        )
      )
        state.alerts = action.payload;
    });
    builder.addCase(deleteAlertsByUser.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { oldAlerts, removedAlerts } = action.payload;
      state.alerts = oldAlerts.filter(
        (alerts) => !removedAlerts.includes(alerts._id)
      );
    });
    builder.addCase(updateCookies.fulfilled, (state, action) => {
      state.cookies = action.payload.cookies;
    });
    // builder.addCase(getUserRoles.fullfilled, (state, action) => {
    //   if (!action.payload) return;
    //   try {
    //     const data = action.payload;
    //   } catch (error) {
    //     console.log('Error: getUserRoles fullfilled');
    //   }
    // });
  },
});

const selectUserState = (state) => state.user;

export const selectUserLanguage = createSelector(
  [selectUserState],
  (userState) => userState.language
);

export const userSliceActions = {
  ...userSlice.actions,
  getTenantsUsers,
  getMyUser,
  updateUser,
  getUserRoles,
  inviteUser,
  createInvitedUser,
  activateUser,
  readAlerts,
  readAlertsByUser,
  deleteAlertsByUser,
  passwordForget,
  checkRecaptchaV3,
  updateCookies,
  readInvitedUsers,
  deleteInvitedUsers,
};

export default userSlice.reducer;
